/* 外商项目推送会商提报 */
<template>
  <v-dialog
    v-model="userSelect"
    width="800px"
    style="overflow-y:none"
  >
    <v-card style="height:100%">
      <v-toolbar
        color="info"
        dark
      >
        <v-toolbar-title>{{ orgName }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="orgValue === 'ROLE_TZCJCFZRSHYJ' && !isFirst"
          color="#B71C1C"
          class="mr-4"
          @click="subback"
        >
          {{ '待完善' }}
        </v-btn>
        <v-btn
          v-if="orgValue === 'ROLE_TZCJCFZRSHYJ'"
          color="#0D47A1"
          @click="submove"
        >
          {{ edit ?'修改并推送': '提交并推送' }}
        </v-btn>
        <v-btn
          v-else
          color="#0D47A1"
          @click="submove"
        >
          {{ edit ?'修改': '提交' }}
        </v-btn>
      </v-toolbar>
      <v-container>
        <div
          v-if="detailList.data && orgValue === 'ROLE_TZCJCNBYJ'"
          class="mt-5 mb-5"
        >
          <b class="red--text text--darken-4">
            项目拟推送{{ tsname }}：
          </b>
          <span>
            {{ formId === wsFormId? detailList.data.inferred :detailList.data.country }}
          </span>
        </div>
        <div
          v-if="editData && editData.data.park&& orgValue === 'ROLE_TZCJCFZRSHYJ' "
          class="mt-5 mb-5"
        >
          <b class="red--text text--darken-4">
            投资促进处拟推送{{ tsname }}：
          </b>
          <span>
            {{ editData.data.park }}
          </span>
        </div>
        <div
          v-if="desserts.length"
          class="mt-5 mb-5"
        >
          <b class="green--text text--darken-4">
            项目已推送{{ tsname }}：
          </b>
          <span
            v-for="(item,inx) in desserts"
            :key="inx"
          >
            {{ item.data.park }},
          </span>
        </div>
        <v-row class="tscNum">
          <!-- <v-col
            v-if="orgValue === 'ROLE_TZCJCFZRSHYJ'"
            cols="4"
            class="pb-0"
          >
            <v-text-field
              v-model="round"
              label="推送轮次（整数）"
              solo
              dense
              clearable
              flat
              type="number"
            />
          </v-col> -->
          <v-col
            v-if="orgValue !== 'ROLE_KFQCNBYJ'"
            cols="6"
            class="pb-0"
          >
            <a-tree-select
              v-model="parkCode"
              tree-checkable
              dropdown-class-name="downname"
              multiple
              tree-check-strictly
              style="width:100%"
              :dropdown-style="{ maxHeight: '58vh' }"
              show-search
              :tree-data="userGroupTree"
              tree-default-expand-all
              allow-clear
              :placeholder="tsname+'（点击展开）'"
              :replace-fields="replaceFields"
              tree-node-filter-prop="title"
              @change="changeOrg"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-textarea
              v-model="opinions"
              outlined
              name="input-7-4"
              label="意见"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import _ from 'lodash'
  export default {
    components: {},
    props: {
      formId: {
        type: String,
        default: null,
      },
      // 父级项目id
      parentId: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        round: '',
        opinions: '',
        parkCode: [],

        // 外商推送列表
        wschildformId: '5afc5ec4-b2a0-49dc-b042-75d0079b396c',
        // 推介推送列表
        tjchildformId: '76fa7297-55e4-4791-a239-897b24dad6a3',
        // 推送列表（默认外商推送列表）
        childformId: '5afc5ec4-b2a0-49dc-b042-75d0079b396c',
        // 项目动态（推送子表单，暂时存在，已不使用）
        dynamicformId: 'cf45fb6b-8c8f-4e1e-b9f8-1b2b774fea2b',
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        orgList: [],
        replaceFields: { title: 'name', value: 'id' },
        userGroupTree: [],
        userSelect: false,
        desserts: [],
        parentOrgId: '',
        allGroupTree: [],
        detailList: {},
        // 会商意见表(共用)
        searchParame: {
          formId: 'd3ced02a-aee1-48dc-9a54-93323876d62c',
          _from: 'public',
          page: 0,
        },
        orgValue: '',
        orgName: '项目推送会商',
        isadd: false,
        // 编辑时会商信息
        editData: null,
        edit: false,
        isHshow: false,
        isFirst: false,
        tsname: '市商务（投促）部门',
        // 会商审核 编辑时传过来编辑后的项目信息
        openReportList: null,
        respData: [],
        pushed: '',
      }
    },
    async mounted () {
      await this.getdb()
      // 根据父级传的formId更换相应推送列表
      if (this.formId === this.wsFormId) {
        this.childformId = this.wschildformId
        this.tsname = '市商务（投促）部门'
      } else if (this.formId === this.tjFormId) {
        this.childformId = this.tjchildformId
        this.tsname = '目标'
      }
      this.init()
    },
    methods: {
      // 获取代表处字典
      async getdb () {
        const resp = await this.$axios.get('/dictionaries/e4f801f6-2c03-4575-aa9b-27bc091a444f')
        const respData = resp.data.data
        this.respData = respData
      },
      // 代表处转换
      async changedb (name) {
        const names = name.split(',')
        for (let inx = 0; inx < this.respData.length; inx++) {
          for (let j = 0; j < names.length; j++) {
            if (this.respData[inx].code === names[j]) {
              names.splice(names[j], 1, this.respData[inx].name)
            }
          }
        }
        return names.join()
      },
      async open (type, editData) {
        if (type === 'first') {
          this.isFirst = true
        }
        // 获取已推送(防止再次编辑时树状框中显示错误，已推送的不再显示，搭配市级树状信息)
        await this.getchild()
        // 市级树状信息
        if (this.formId === this.wsFormId) {
          await this.getUserGroupTree()
        } else if (this.formId === this.tjFormId) {
          await this.getUserGroupTree2()
        }
        if (editData) {
          console.log('editData', editData);
          // 会商信息赋值
          this.editData = editData
          this.edit = true
          // 给各角色赋值意见等信息
          await this.getMeInfo()
          await this.liPark()
        } else {
          // 返回第一个会商意见(当第一次负责人审核时 获取拟办的拟推送)
          this.editData = await this.getOpinion()
          console.log('this.editData', this.editData);

          // 项目有推送信息 给拟推送赋值，拟推送有值给负责人赋值
          await this.liPark()
        }
        this.userSelect = true
      },
      // 会商审核人员编辑时传过来的项目信息
      openReport (formId, id, filterFormData, weight, requert) {
        this.openReportList = { formId, id, filterFormData, weight, requert }
        console.log('this.openReportList', this.openReportList);
      },
      async init () {
        // 父级项目信息
        await this.getDetail()
        // 个人信息
        await this.getMeInfo()
        // 获取已推送
        await this.getchild()
        // 市级树状信息
        if (this.formId === this.wsFormId) {
          await this.getUserGroupTree()
        } else if (this.formId === this.tjFormId) {
          await this.getUserGroupTree2()
        }
      },
      async getMeInfo () {
        const res = await this.$axios.get('/me')
        console.log('res', res)
        const roleName = res.data.data.roles
        // 投资促进处负责人
        if (roleName.includes('ROLE_TZCJCFZRSHYJ')) {
          this.orgValue = 'ROLE_TZCJCFZRSHYJ'
          this.orgName = '投资促进处负责人审核意见'
          this.isHshow = true
          if (this.editData) {
            this.opinions = this.editData.data.tzcfzrOpinion
            this.round = this.editData.data.round
          }
        // 开发区处
        } else if (roleName.includes('ROLE_KFQCNBYJ')) {
          this.orgValue = 'ROLE_KFQCNBYJ'
          this.orgName = '开发区处拟办意见'
          this.isHshow = true
          if (this.editData) {
            this.opinions = this.editData.data.kfqOpinion
          }
        // 投资促进处
        } else if (roleName.includes('ROLE_TZCJCNBYJ')) {
          this.orgValue = 'ROLE_TZCJCNBYJ'
          this.orgName = '投资促进处拟办意见'
          this.isHshow = true
          if (this.editData) {
            this.opinions = this.editData.data.tzcOpinion
          }
        } else {
          this.orgValue = ''
          this.isHshow = false
        }
        console.log('this.orgValue', this.orgValue)
      },
      async liPark () {
        this.orgList = []
        this.parkCode = []
        if (!this.edit) {
          console.log('this.detailList', this.detailList, this.editData);
          // 负责人赋值
          if (
            this.orgValue === 'ROLE_TZCJCFZRSHYJ' &&
            this.detailList.data &&
            this.editData &&
            this.editData.data.parkId
          ) {
            console.log('valp')
            this.allGroupTree.map((val) => {
              this.editData.data.parkId.split(',').map((valp) => {
                if (val.id === valp) {
                  // 已推送的不默认显示在树状框内
                  if (this.desserts.length) {
                    const list = this.desserts.filter((vam) => {
                      return vam.data.parkId === valp
                    })
                    if (list.length) {
                      return false
                    } else {
                      const list = { value: val.id, label: val.name }
                      this.parkCode.unshift(list)
                    }
                  } else {
                    const list = { value: val.id, label: val.name }
                    this.parkCode.unshift(list)
                  }
                }
              })
            })
          }
          // 拟办赋值

          // 根据父级传的formId更换相应项目中拟办
          if (this.formId === this.wsFormId) {
            if (
              this.orgValue === 'ROLE_TZCJCNBYJ' &&
              this.detailList.data.inferred &&
              this.detailList.data
            ) {
              this.allGroupTree.map((val) => {
                this.detailList.data.inferred.split(',').map((valp) => {
                  if (val.name === valp) {
                    const list = { value: val.id, label: val.name }
                    this.parkCode.unshift(list)
                  }
                })
              })
            }
          } else if (this.formId === this.tjFormId) {
            if (
              this.orgValue === 'ROLE_TZCJCNBYJ' &&
              this.detailList.data.country &&
              this.detailList.data
            ) {
              this.allGroupTree.map((val) => {
                this.detailList.data.country.split(',').map((valp) => {
                  if (val.name === valp) {
                    const list = { value: val.id, label: val.name }
                    this.parkCode.unshift(list)
                  }
                })
              })
            }
          }
        } else {
          // 拟办赋值
          if (
            this.orgValue === 'ROLE_TZCJCNBYJ' &&
            this.editData &&
            this.editData.data.parkId
          ) {
            this.allGroupTree.map((val) => {
              this.editData.data.parkId.split(',').map((valp) => {
                if (val.id === valp) {
                  // 已推送的不默认显示在树状框内(拟办先不处理)
                  // if (this.desserts.length) {
                  //   const list = this.desserts.filter((vam) => {
                  //     return vam.data.parkId === valp
                  //   })
                  //   if (list.length) {
                  //     return false
                  //   } else {
                  //     const list = { value: val.id, label: val.name }
                  //     this.parkCode.unshift(list)
                  //   }
                  // } else {
                  const list = { value: val.id, label: val.name }
                  this.parkCode.unshift(list)
                  // }
                }
              })
            })
          }
          // 负责人赋值
          if (
            this.orgValue === 'ROLE_TZCJCFZRSHYJ' &&
            this.editData &&
            this.editData.data.parkId1
          ) {
            console.log('valp')
            console.log('this.editData', this.editData, 'this.desserts', this.desserts);

            this.allGroupTree.map((val) => {
              this.editData.data.parkId1.split(',').map((valp) => {
                if (val.id === valp) {
                  // 已推送的不默认显示在树状框内
                  if (this.desserts.length) {
                    const list = this.desserts.filter((vam) => {
                      return vam.data.parkId === valp
                    })
                    if (list.length) {
                      return false
                    } else {
                      const list = { value: val.id, label: val.name }
                      this.parkCode.unshift(list)
                    }
                  } else {
                    const list = { value: val.id, label: val.name }
                    this.parkCode.unshift(list)
                  }
                }
              })
            })
          }
        }
        console.log('this.parkCode', this.parkCode)

        this.orgList = this.parkCode
      },
      subback () {
        this.userSelect = false
        this.$swal({
          title: '你确定该项目会商需要待完善吗？',
          text: '此操作将重新进入会商流程。',
          input: 'textarea',
          animation: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showLoaderOnConfirm: true,
          inputPlaceholder: '请填写待完善理由',
          preConfirm: (content) => {
            if (content === '') {
              this.$swal.showValidationMessage(
                '待完善理由不能为空！',
              )
            }
          },
        }).then(async (result) => {
          if (result.value) {
            // 更改会商待完善字段
            await this.$axios.patch(`/reports/${this.editData.id}`, {
              // 投资促进处拟办意见
              isBack: '是',
              // 开发区处拟办意见
              isBack1: '是',
              // 投资促进处负责人审核意见
              isBack2: '是',
              reason: result.value,
            })
            // 更改项目字段
            this.$axios.patch(`/reports/${this.parentId}`, {
              tzcHandle: '待完善',
              kfqHandle: '待完善',
              tzcfzrHandle: '待完善',
            })
              .then(async res => {
                this.userSelect = false
                if (this.openReportList) {
                  this.openReportList.filterFormData.tzcHandle = '待完善'
                  this.openReportList.filterFormData.kfqHandle = '待完善'
                  this.openReportList.filterFormData.tzcfzrHandle = '待完善'
                  await this.$emit('pushInitChild', this.openReportList)
                } else {
                  await this.$emit('pushInitChild')
                }
                this.$swal({
                  title: '待完善成功',
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
              .catch(err => {
                this.$swal({
                  title: '待完善失败',
                  icon: 'error',
                  text: '原因:' + err,
                  confirmButtonText: '知道了',
                })
              })
          }
        })
      },
      async getOpinion () {
        const searchParame = this.searchParame
        searchParame.parentID = this.parentId
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        })
        const list = resp.data.data.content
        return list[0]
      },
      async getDetail () {
        const resp = await this.$axios.get(
          '/reports/' + this.parentId + '/detail',
        )
        this.detailList = resp.data.data
				console.log('this.detailList.data.country',this.detailList.data.country);
        // if (this.detailList.data.country) {
        //   this.$set(this.detailList.data, 'country', await this.changedb(this.detailList.data.country))
        // }
      },
      async getchild () {
        const paramsData = {
          formId: this.childformId,
          page: 0,
          size: 999,
          parentID: this.parentId,
        }
        const resp = await this.$axios.get('/reports/query', {
          params: paramsData,
        })
        this.desserts = resp.data.data.content
        const list = []
        this.desserts.map(val => {
          list.push(val.data.park)
        })
        this.pushed = list.join(',')
        console.log('this.desserts', this.desserts, this.pushed);
      },
      async getUserGroupTree () {
        const res = await this.$axios.get('/dictionaries/roles')
        const resData = res.data.data
        this.allGroupTree = resData
        resData.map((val) => {
          // 推送市商务（投促）部门为父级
          if (val.value === 'ORG_TUISONGYUANQU') {
            this.parentOrgId = val.id
            const userGroupTree = this.treeData(resData, val.id)
            userGroupTree.sort((a, b) => {
              return a.weight - b.weight
            })
            this.userGroupTree = userGroupTree
            console.log('userGroupTree', userGroupTree);
          }
        })
      },

      treeData (sourceData, id) {
        const cloneData = JSON.parse(JSON.stringify(sourceData))
        return cloneData.filter((father) => {
          // 如果推送了直接禁用  不能再推送相同的
          if (this.desserts.length) {
            this.desserts.map(vam => {
              if (father.id === vam.data.parkId) {
                console.log('father', father);
                this.$set(father, 'disabled', true)
              }
            })
          }
          const branchArr = cloneData.filter(
            (child) => father.id === child.parentId,
          )
          return father.parentId + '' === id + ''
        })
      },

      async getUserGroupTree2 () {
        const res = await this.$axios.get('/dictionaries/roles')
        const resData = res.data.data
        this.allGroupTree = resData
        resData.map((val) => {
          // 投资促进中心为父级
          if (val.value === 'ORG_TZCJZX') {
            this.parentOrgId = val.id
            const userGroupTree = this.treeData2(resData, val.id)
            userGroupTree.sort((a, b) => {
              return a.weight - b.weight
            })
            console.log('userGroupTree', userGroupTree)

            this.userGroupTree = userGroupTree
          }
        })
      },

      treeData2 (sourceData, id) {
        const cloneData = JSON.parse(JSON.stringify(sourceData))
        return cloneData.filter((father) => {
          // 如果推送了直接禁用  不能再推送相同的
          if (this.desserts.length) {
            this.desserts.map(vam => {
              if (father.id === vam.data.parkId) {
                console.log('father', father);
                this.$set(father, 'disabled', true)
              }
            })
          }
          // 园区父级id
          if (father.id === '8532ec90-3601-4d52-8c7c-c1cd99a11aa8') {
            return
          }
          const branchArr = cloneData.filter(
            (child) => father.id === child.parentId,
          )
          branchArr.length > 0 ? father.children = branchArr : father.children = []
          return father.parentId + '' === id + ''
        })
      },
      async changeOrg (value) {
        this.orgList = value
      },
      submove:
        _.throttle(async function () {
          // 投资促进处
          if (this.orgValue === 'ROLE_TZCJCNBYJ') {
            // if (this.orgList.length === 0) {
            //   this.selPark('请先选择推送市商务（投促）部门！')
            // } else
            // if (!this.opinions) {
            //   this.selPark('请填写意见！')
            // } else {
            // 创建跟踪表
            await this.opinion()
          // }
            // 开发区处
          } else if (this.orgValue === 'ROLE_KFQCNBYJ') {
            // 更新开发区意见
            // if (!this.opinions) {
            //   this.selPark('请填写意见！')
            // } else {
            await this.putOpinion(
              {
                kfqOpinion: this.opinions ? this.opinions : '无',
              },
              'kfqHandle',
            )
          // }
            // 投资促进处负责人
          } else if (this.orgValue === 'ROLE_TZCJCFZRSHYJ') {
            // if (this.orgList.length === 0) {
            //   this.selPark('请先选择推送市商务（投促）部门！')
            // } else
            // if (!this.opinions) {
            //   this.selPark('请填写意见！')
            // } else {
            // 先更新再推送
            const formData = {}
            const labels = []
            const values = []
            this.parkCode.map((val) => {
              labels.push(val.label)
              values.push(val.value)
            })
            formData.park1 = labels.toString()
            formData.parkId1 = values.toString()
            formData.tzcfzrOpinion = this.opinions ? this.opinions : '无'
            formData.round = this.round
            await this.putOpinion(formData, 'tzcfzrHandle')
            await this.pushmove()
          // }
          }

          // 项目编辑页进来先更新项目信息，再提交会商意见，防止会商意见更新的项目信息被覆盖
          if (this.openReportList) {
            await this.$emit('pushInitChild', this.openReportList)
          } else {
            await this.$emit('pushInitChild')
          }
        }, 3000, { trailing: false }),
      async putOpinion (formData, name) {
        // 如果是待完善的，编辑后重新让开发区及促进审核处进入待处理
        if (name === 'tzcHandle' && this.editData.data.isBack === '是') {
          formData.isBack = null
          await this.partHandle('tzcHandle', 'back')
        } else if (name === 'kfqHandle' && this.editData.data.isBack1 === '是') {
          formData.isBack1 = null
          await this.partHandle('kfqHandle', 'back1')
        } else if (name === 'tzcfzrHandle' && this.editData.data.isBack2 === '是') {
          formData.isBack2 = null
          await this.partHandle('tzcfzrHandle', 'back2')
        }
        const resp = await this.$axios.patch(
          `/reports/${this.editData.id}`,
          formData,
        )
        if (resp.data.code !== 200) {
          return this.$swal({
            title: this.edit ? '修改失败' : '提交失败！',
            icon: 'error',
          })
        }
        if (!this.edit) {
          await this.partHandle(name)
        }
        // await this.$emit('pushInitChild')
        this.userSelect = false
        this.$swal({
          title: this.edit ? '修改成功' : '提交成功！',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
      },
      async opinion () {
        // 创建推进表
        const formData = {}
        formData.parentID = this.parentId
        console.log('parkCode', this.parkCode)
        const labels = []
        const values = []
        this.parkCode.map((val) => {
          labels.push(val.label)
          values.push(val.value)
        })
        formData.name = this.detailList.data.name
        formData.parentFormId = this.detailList.formId
        formData.park = labels.toString()
        formData.parkId = values.toString()
        formData.tzcOpinion = this.opinions ? this.opinions : '无'
        // 修改
        if (this.edit) {
          this.putOpinion(formData, 'tzcHandle')
        } else {
          const resp = await this.$axios.post(
            `/forms/${this.searchParame.formId}/reports3`,
            {
              data: formData,
              status: 0,
              weight: 10000,
              parentId: this.parentId,
            },
          )
          // 第一次并且不是项目编辑页进来的
          if (this.isFirst && !this.openReportList) {
            this.adopt()
          }
          console.log('resp', resp)
          if (resp.data.code !== 200) {
            return this.$swal({
              title: '提交失败！',
              icon: 'error',
            })
          }
          await this.partHandle('tzcHandle')
          // await this.$emit('pushInitChild')
          this.userSelect = false
          this.$swal({
            title: '提交成功！',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      },
      selPark (text) {
        return this.$swal({
          title: text,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '知道了',
        })
      },
      async pushmove () {
        const filterFormData = {}
        filterFormData.stageStatus = '待处理'
        filterFormData.industry = this.detailList.data.industry
        filterFormData.fillingUser = this.detailList.data.fillingUser
        filterFormData.parentName = this.detailList.name
        filterFormData.parentID = this.parentId
        filterFormData.parentFormId = this.detailList.formId
        var code = 200
        // 没选择市区不推送
        if (this.orgList.length) {
          for (let inx = 0; inx < this.orgList.length; inx++) {
            filterFormData.name =
              this.detailList.name + '--' + this.orgList[inx].label
            filterFormData.park = this.orgList[inx].label
            filterFormData.parkId = this.orgList[inx].value
            // 只要市区id和名称
            this.allGroupTree.map((vam) => {
              // 市区的
              if (
                vam.id === this.orgList[inx].value &&
                vam.parentId === this.parentOrgId
              ) {
                filterFormData.parkIds = this.orgList[inx].value
                filterFormData.parks = this.orgList[inx].label
                // 园区的
              } else if (
                vam.id === this.orgList[inx].value &&
                vam.parentId !== this.parentOrgId
              ) {
                // filterFormData.parkIds = vam.parentId + ',' + this.orgList[inx].value
                filterFormData.parkIds = vam.parentId
                this.allGroupTree.map((vas) => {
                  if (vam.parentId === vas.id) {
                    filterFormData.parks = vas.name
                  }
                })
              }
            })
            // 创建推送表
            const resp = await this.$axios.post(
              `/forms/${this.childformId}/reports3`,
              {
                data: filterFormData,
                status: 0,
                weight: 10000,
                parentId: this.parentId,
              },
            )
            code = resp.data.code
            console.log('resp', resp, resp.data.code)
            // 给市区授权这个推送
            this.putGroup(resp.data.data.id, this.orgList[inx].value)
            // 创建推进下的动态(下面授权)
            this.release(resp.data.data, this.orgList[inx].value)
          }
          // 更新项目的推送
          await this.partpush('是')
        } else { // 创建推进表
          code = 200
        }
        if (this.isFirst && !this.openReportList) {
          this.adopt()
        }
        await this.getchild()
        // await this.$emit('pushInitChild')
        if (code === 200) {
          this.userSelect = false
          this.$swal({
            title: this.orgList.length ? '推送成功！' : '提交成功！',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      },
      // 更新各处办理情况
      async partHandle (name, type) {
        const list = {}
        if (name === 'tzcHandle') {
          list.tzcHandle = '已办理'
          list.kfqHandle = '待办理'
          list.tzcfzrHandle = '待办理'
          if (this.openReportList) {
            this.openReportList.filterFormData.tzcHandle = '已办理'
            this.openReportList.filterFormData.kfqHandle = '待办理'
            this.openReportList.filterFormData.tzcfzrHandle = '待办理'
          }
        } else if (name === 'kfqHandle') {
          list.kfqHandle = '已办理'
          if (this.openReportList) {
            this.openReportList.filterFormData.kfqHandle = '已办理'
          }
        } else if (name === 'tzcfzrHandle') {
          list.tzcfzrHandle = '已办理'
          if (this.openReportList) {
            this.openReportList.filterFormData.tzcfzrHandle = '已办理'
          }
        }
        const resp = await this.$axios.patch(`/reports/${this.parentId}`, list)
        console.log('resp', resp)
      },
      // 更新是否推送
      async partpush (name) {
        // 获取已推送单位更新
        await this.getchild()
        const resp = await this.$axios.patch(`/reports/${this.parentId}`, {
          isPush: name,
          projectStatus2: name === '是' ? '已推送' : '待推送',
          projectTime2: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
          pushed: this.pushed,
        })
        if (this.openReportList && name === '是') {
          this.openReportList.filterFormData.projectStatus2 = '已推送'
          this.openReportList.filterFormData.isPush = '是'
        }
        console.log('resp2222', resp)
      },
      async release (item, groupId) {
        const filterFormData2 = {}
        filterFormData2.dynamicId = item.id
        filterFormData2.parentName = this.detailList.name
        filterFormData2.parentID = this.parentId
        filterFormData2.content = '待处理'
        filterFormData2.stageStatus = '待处理'
        filterFormData2.park = item.data.park
        filterFormData2.parkId = item.data.parkId
        filterFormData2.parkIds = item.data.parkIds
        filterFormData2.name = this.detailList.name + '--待处理'
        const resp = await this.$axios.post(
          `/forms/${this.dynamicformId}/reports3`,
          {
            data: filterFormData2,
            status: 0,
            weight: 10000,
            // 推送表id
            parentId: item.id,
          },
        )
        console.log('resp', resp)
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '未知错误!',
            icon: 'error',
          })
        }
        // 授权这个推送
        this.putGroup(resp.data.data.id, groupId)
        // 授权这个项目
        this.putGroup(this.parentId, groupId)
      },
      // 授权给市商务（投促）部门
      async putGroup (parentId, groupIds) {
        const res = await this.$axios.post(`/reports/${parentId}/access`, {
          grantId: groupIds,
          type: 'role',
          permission: 'read,write',
        })
        if (res.data.code !== 200) {
          return this.$swal({
            title: '授权失败!',
            icon: 'error',
          })
        }
        console.log(res)
      },

      //! 审核项目的实际动作
      adopt () {
        this.$axios.put('/reports/' + this.parentId + '/audit', {
          message: '审核通过',
          status: '10',
        })
          .then(() => {
            this.Lightpush(this.parentId)
            this.OfflineAuditDoubts()
          })
          .catch((result) => {
            this.$swal({
              title: '通过审核失败!',
              text: result.response.data.msg,
              icon: 'error',
              confirmButtonText: '确定',
            })
          })
      },
      // 更新项目亮灯字段
      async Lightpush (id) {
        const resp = await this.$axios.patch(`/reports/${id}`, {
          projectLight: null,
        })
        console.log('resp', resp);
      },

      // 下线存疑项目
      async OfflineAuditDoubts () {
        const auditDoubts = await this.$axios.get('/audit-doubts', {
          params: {
            parentId: this.parentId,
          },
        })
        if (auditDoubts.data.data.length > 0) {
          const AuditDoubtsId = auditDoubts.data.data.length > 0 ? auditDoubts.data.data[0].id : false
          this.$axios.put(`/audit-doubts/${AuditDoubtsId}/offline`)
        }
        await this.$emit('pushInitChild')
      },
    },
  }
</script>
<style scoped lang='scss'>
::v-deep.v-dialog__content {
  justify-content: flex-end !important;
}
::v-deep .v-dialog {
  height: 86% !important;
}
::v-deep .fildName .v-text-field__details {
  display: none;
}
::v-deep .v-data-table__wrapper {
  max-height: 350px;
}
.tscNum ::v-deep .v-input__slot {
  border: 1px solid #d9d9d9;
  min-height: 33px;
}
</style>
